<template>
  <div class="container">
    <section class="py-4 py-md-5 pb-md-4">
      <div class="row justify-content-between">
        <div class="logo-footer col-xl-2 col-lg-12 col-12">
          <router-link to="/" class="nav-link navbar-brand">
            <img
              src="../assets/image/Logo_rairub_white.png"
              alt="Rairub"
              width="143px"
            />
          </router-link>
        </div>
        <div class="col-xl-7 col-lg-12 col-12 show-dt">
          <div class="row pt-lg-4 pt-md-3 pt-3 row-cols-md-4 row-cols-2">
            <div class="col align-self-start">
              <p class="mb-3">Feature</p>
              <ul class="p-0">
                <li>
                  <router-link to="/" class="nav-link"
                    >Budget Buddy</router-link
                  >
                </li>
                <li>
                  <router-link to="/" class="nav-link">Stock Check</router-link>
                </li>
                <li>
                  <router-link to="/" class="nav-link">Due Dates</router-link>
                </li>
              </ul>
            </div>
            <div class="col align-self-start">
              <p class="mb-3">Price</p>
              <ul class="p-0">
                <li>
                  <router-link to="/" class="nav-link">ราคาแพ็คเกจ</router-link>
                </li>
                <li>
                  <router-link to="/" class="nav-link"
                    >คำถามที่พบบ่อย</router-link
                  >
                </li>
                <li>
                  <router-link to="/blog" class="nav-link">บทความ</router-link>
                </li>
              </ul>
            </div>
            <div class="col align-self-start">
              <p class="mb-3">Company</p>
              <ul class="p-0">
                <li>
                  <router-link to="/about" class="nav-link"
                    >About us</router-link
                  >
                </li>
                <li>
                  <router-link to="/about" class="nav-link"
                    >ร่วมงานกับเรา</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col align-self-start">
              <p class="mb-3">Policy</p>
              <ul class="p-0">
                <li>
                  <router-link to="/privacy" class="nav-link"
                    >Privacy Policy</router-link
                  >
                </li>
                <li>
                  <router-link to="/cookies" class="nav-link"
                    >Cookies Policy</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="btn-footer col mt-lg-4">
          <button type="button" class="btn btn-outline-light">
            เข้าสู่ระบบ
          </button>
          <button type="button" class="btn btn-primary mx-2">
            สมัครใช้งาน
          </button>
        </div>
      </div>
      <div
        class="d-flex justify-content-center justify-content-lg-end justify-content-sx-center mt-3 mt-md-0 social-footer"
      >
        <div>
          <a href="http://www.facebook.com/rairub" target="_blank">
            <img src="../assets/image/icon-facebook.svg" />
          </a>
        </div>
        <div class="mx-3">
          <a href="http://www.facebook.com/rairub" target="_blank">
            <img src="../assets/image/icon-line.svg" />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/rairub_com/" target="_blank">
            <img src="../assets/image/icon-instagram.svg" />
          </a>
        </div>
        <div class="mx-3 mr-0">
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2Frairub_com"
            target="_blank"
          >
            <img src="../assets/image/icon-twitter.svg" />
          </a>
        </div>
      </div>
    </section>
    <hr class="m-0" />
    <div class="py-4 text-center">
      <div class="col-12">
        <small>2023, All Rights Reserved</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>

<style lang="scss">
footer {
  background: url(../assets/image/bg-footer.png) no-repeat left top #000;
  background-color: #151515;
  background-size: contain;
  color: #fff;
}
footer p {
  font-size: 21px;
  font-weight: 500;
  color: #fce501;
}
footer a {
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fce501;
  }
}
footer li {
  list-style: none;
  font-weight: 300;
  margin-bottom: 12px;
}
.social-footer img {
  width: 40px;
  height: auto;
  transition: transform 0.4s;
}
.social-footer img:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
@media (max-width: 991px) {
  footer .logo-footer {
    text-align: center;
    margin-bottom: 20px;
  }
  footer .navbar-brand {
    margin: 0 auto;
  }
  footer .btn-footer {
    text-align: center;
    margin: 10px auto;
  }
  footer .btn-footer .btn {
    width: 40%;
  }
  footer .show-dt {
    display: none;
  }
}
@media (max-width: 768px) {
  footer {
    background-size: cover;
  }
}
</style>
