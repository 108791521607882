<template>
  <section class="page-home">
    <div class="section-main position-relative">
      <div class="container">
        <div class="section-main-in">
          <div class="row">
            <div class="col-12">
              <h1 class="display-6 fw-bold text-primary">
                เพราะทำรายรับรายจ่าย<br />จึงสบายเรื่องการเงิน
              </h1>
              <p class="fw-normal font2 py-3">
                บริหารการเงินธุรกิจให้เป็นระบบ เพื่อรองรับการเติบโต<br />
                อย่างมั่นคง ในอนาคต ด้วยระบบออนไลน์ ที่ทันสมัยและ<br />
                ใช้วิเคราะห์รายจ่ายได้อย่างมีประสิทธิภาพ
              </p>
              <button type="button" class="btn btn-primary">
                ทดลองใช้ฟรี :)
              </button>
            </div>
          </div>
        </div>
      </div>

      <img src="../assets/image/img-main.png" alt="" class="w-100 show-dt" />
      <img
        src="../assets/image/img-main-mb.png"
        alt=""
        class="img-fluid show-mb"
      />
      <button type="button" class="btn btn-primary show-mb">
        ทดลองใช้ฟรี :)
      </button>
    </div>

    <!-- <div class="section-scroll"> -->
    <div
      class="reveal text-center container py-5"
      :class="{ active: isElementVisible }"
    >
      <h2 class="text-primary">ตอบโจทย์การใช้งานที่ง่ายกว่าเดิม</h2>
      <p class="font2 mt-4">
        ช่วยวิเคราะห์เพื่อปรับลดรายจ่ายที่ไม่จำเป็น วางแผนการใช้จ่ายในอนาคต<br />
        ได้อย่างมีประสิทธิภาพ และทำให้เกิดกำไรให้ได้มากที่สุด
      </p>
      <div class="row mt-4">
        <div class="col align-self-center">
          <div class="col-md-10 offset-md-1">
            <img
              src="../assets/image/free-dashboard-templates-1.png"
              alt="dashboard"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- <div class="section-scroll"> -->
    <div class="reveal container py-5" :class="{ active: isElementVisible }">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="text-center item-description">
            <div class="py-3">
              <img
                src="../assets/image/icon-budget.png"
                alt="Budget Buddy Feature"
                class="img-fluid"
              />
            </div>
            <h3 class="text-primary">BUDGET BUDDY</h3>
            <p class="font2">
              ฟีเจอร์บันทึกรายรับ-จ่าย แบบแยกหมวดหมู่<br />
              ที่กำหนดได้เอง สามารถดูสรุปตามหมวดหมู่<br />
              ช่วยให้การบันทึกมีประสิทธิภาพยิ่งขึ้น
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 text-center">
          <div class="text-center item-description">
            <div class="py-3">
              <img
                src="../assets/image/icon-stock.png"
                alt="StockCheck Feature"
                class="img-fluid"
              />
            </div>
            <h3 class="text-primary">STOCK CHECK</h3>
            <p class="font2">
              ฟีเจอร์ตัวช่วยตัดสต๊อกแบบอัตโนมัติ<br />
              เมื่อบันทึกการขาย มีระบบสต๊อกสินค้า<br />
              และมีระบบเตือนสต๊อกใกล้หมดอีกด้วย
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="text-center item-description">
            <div class="py-3">
              <img
                src="../assets/image/icon-due-date.png"
                alt="Due Dates Feature"
                class="img-fluid"
              />
            </div>
            <h3 class="text-primary">DUE DATES</h3>
            <p class="font2 mb-0">
              ฟีเจอร์แจ้งเตือนทุกรายการรับ-จ่ายที่ใกล้ถึง<br />
              วันกำหนดชำระ สามารถสร้างบิลการใช้จ่าย<br />
              เพื่อให้เป็นรายจ่าย ประจำเตือนทุกเดือนได้
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- <div class="section-scroll"> -->
    <div
      class="reveal section-secondary pt-5"
      :class="{ active: isElementVisible }"
    >
      <div class="container">
        <div class="row justify-content-between mb-5">
          <div class="col-12 col-md-6 col-lg-5 d-md-none">
            <img
              src="../assets/image/img-rairub01.png"
              alt="เริ่มต้นจากการมีระบบที่ดี นำไปสู่การสร้างธุรกิจที่ยั่งยืน"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-md-6 col-lg-5 py-md-5">
            <h2 class="text-center text-md-start text-primary">
              เริ่มต้นจากการมีระบบที่ดี<br />นำไปสู่การสร้างธุรกิจที่ยั่งยืน
            </h2>
            <p class="font2 my-4">
              ถ้าการบันทึกแบบเดิมๆ ไม่ทำให้การใช้จ่ายเป็นระบบได้
              ธุรกิจจำเป็นต้อง<br class="d-sm-none d-md-block" />
              มีระบบที่ดีและปลอดภัย เพื่อให้ทุกการรับจ่ายอยู่ในสายตาไม่ตกหล่น
              ทุกรายการบันทึกจะถูกเก็บไว้อย่างปลอดภัยในระบบออนไลน์สำหรับ<br
                class="d-sm-none d-md-block"
              />
              คุณโดยเฉพาะ เพื่อให้สามารถใช้งานได้ตลอด 24 ชั่วโมง
            </p>
            <button type="button" class="btn btn-primary btn-sm-center">
              <a href="/register.html">เริ่มต้นใช้งาน</a>
            </button>
          </div>
          <div class="col-12 col-md-5 text-end d-md-block d-sm-none d-none">
            <img
              src="../assets/image/img-rairub01.png"
              alt="เริ่มต้นจากการมีระบบที่ดี นำไปสู่การสร้างธุรกิจที่ยั่งยืน"
              class="img-fluid"
            />
          </div>
        </div>
        <div
          class="row justify-content-lg-between justify-content-center mt-sm-5"
        >
          <div class="col-7 col-md-4 col-lg-5">
            <img
              src="../assets/image/img-rairub02.png"
              alt="ทำรายรับรายจ่ายส่งผลดีต่อธุรกิจอย่างไร?"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-md-8 col-lg-5 py-lg-5 py-4">
            <h2 class="text-center text-md-start text-primary">
              ทำรายรับรายจ่าย ส่งผลดีต่อธุรกิจอย่างไร?
            </h2>
            <p class="font2 my-4">
              การทำธุรกิจ โดยไม่บันทึกรายรับรายจ่ายนั้น ก็ไม่ต่างกับการ
              พิมพ์งานแล้วไม่ได้กดบันทึก ถ้าเราไม่จดบันทึก แน่นอนว่าไม่มีทาง
              จำได้ทั้งหมด ยิ่งการทำธุรกิจ เงินทุกบาทที่จ่ายไปหมายถึง ต้นทุน
            </p>
            <p class="font2 my-4">
              Rairub เป็นเครื่องมือช่วยบันทึกรายรับ-จ่าย ทำให้
              การบริหารธุรกิจเป็นเรื่องง่าย ด้วยฟีเจอร์เด็ดๆ เหล่านี้
            </p>
            <div class="row mb-2 font2">
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">บันทึกแยกบัญชี แยกหมวดหมู่</p>
              </div>
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">
                  กำหนดบัญชีที่ใช้รับและจ่ายได้หลายบัญชี
                </p>
              </div>
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">
                  มีระบบร้านค้า ตัดสต็อกอัตโนมัติ
                </p>
              </div>
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">มีระบบเตือนเตือนเติมสต็อก</p>
              </div>
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">
                  มีระบบแจ้งเตือน รับ-จ่าย ประจำ
                </p>
              </div>
              <div class="col-12">
                <box class="d-inline-block"
                  ><img src="../assets/image/icon-list.svg" alt="img-fluid"
                /></box>
                <p class="d-inline-block px-2">
                  ปรับแต่งให้เหมาะกับธุรกิจของคุณได้
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->

    <!-- <div class="section-scroll"> -->
    <div
      class="reveal container pt-5 call-center mb-5"
      :class="{ active: isElementVisible }"
    >
      <div class="row gy-4 justify-content-center">
        <div class="col-12 col-lg-4">
          <h2 class="text-center text-md-start text-primary">
            "Rairub" ตอกย้ำ<br />ความสำเร็จของทุกธุรกิจ
          </h2>
          <p class="my-4 font2">
            ธุรกิจที่ประสบความสำเร็จอย่างแท้จริง คือธุรกิจที่มีระบบ<br />
            จัดการที่ดี ถ้าพร้อมที่จะผลักดันให้ธุรกิจประสบความสำเร็จ
            เรามาเริ่มต้นใช้ระบบ รายรับรายจ่ายไปด้วยกัน
          </p>
          <button type="button" class="btn btn-primary btn-sm-center">
            <a href="/register.html">เริ่มต้นใช้งาน</a>
          </button>
        </div>
        <div class="col-12 col-md-7 col-lg-7 text-end">
          <!-- <img src="../assets/image/img-call-center.png" alt="" class="img-fluid"> -->
          <div class="row gx-3">
            <div class="col-4">
              <img
                src="../assets/image/img-call-center01.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-4">
              <img
                src="../assets/image/img-call-center02.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-4">
              <img
                src="../assets/image/img-call-center03.png"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </section>
</template>

<script>
export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      isElementVisible: false,
    };
  },
  methods: {
    reveal() {
      var windowHeight = window.innerHeight;
      var elementTop = this.$el.getBoundingClientRect().top;
      var elementVisible = 500;

      this.isElementVisible = elementTop < windowHeight - elementVisible;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.reveal);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.reveal);
  },
};
</script>

<style lang="scss" scoped>
// .reveal {
//   /* Your initial styles here */
//   opacity: 0;
//   transform: translateY(20px);
//   transition: opacity 0.5s, transform 0.5s;
// }

// .reveal.active {
//   /* Your styles for when the element is visible */
//   opacity: 1;
//   transform: translateY(0);
// }

.page-home .section-dashboard .start-50 {
  left: 48% !important;
}
.page-home .section-dashboard .top-50 {
  top: 57% !important;
}
.icon-number {
  height: 260px;
  width: auto;
}
.call-center img {
  border-radius: 10px;
}
.page-home a {
  text-decoration: none;
  color: #000;
}
@media (min-width: 440px) {
  .section-main-in {
    position: relative;
    margin-bottom: -42%;
    padding-top: 10%;
  }
}
@media (max-width: 768px) {
  .page-home .section-main-in h1 {
    margin-bottom: 40px;
  }
  .page-home .section-main-in p {
    display: none;
  }
  .item-description {
    position: relative;
    display: block;
    margin-top: -100px;
  }
  .icon-number {
    height: 140px;
    width: auto;
  }
  .btn-sm-center {
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 440px) {
  header .btn {
    width: 46%;
    padding: 8px;
  }
  .section-main-in {
    position: absolute;
    top: 10%;
    width: 92%;
    text-align: center;
  }
  .page-home .section-main-in .btn {
    display: none;
  }
  .page-home .section-main .btn {
    width: 80%;
    margin: 0 auto;
    position: absolute;
    bottom: 48px;
    left: 10%;
  }
}
</style>
