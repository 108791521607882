<template>
  <div class="header-admin">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <router-link to="/" class="nav-link navbar-brand">
          <img
            alt="logo-rairub"
            src="../assets/image/Logo_rairub_black.png"
            width="98px"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarScroll">
          <ul
            class="navbar-nav m-auto my-4 my-lg-0 navbar-nav-scroll text-center"
            style="--bs-scroll-height: 450px"
          >
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/price" class="nav-link">Feature</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/price" class="nav-link">Price</router-link>
            </li>
            <li class="nav-item show-mb">
              <router-link to="/price" class="nav-link"
                >ราคาแพ็คเกจ</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/blog" class="nav-link">Blog</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/about" class="nav-link">About us</router-link>
            </li>
            <li class="nav-item show-mb">
              <router-link to="/" class="nav-link">ร่วมงานกับเรา</router-link>
            </li>
            <li class="nav-item show-mb">
              <router-link to="/" class="nav-link">คำถามที่พบบ่อย</router-link>
            </li>
            <li class="nav-item show-mb">
              <router-link class="nav-link" to="/privacy"
                >Privacy Policy</router-link
              >
            </li>
            <li class="nav-item show-mb">
              <router-link class="nav-link" to="/cookies"
                >Cookies Policy</router-link
              >
            </li>
          </ul>
          <div class="text-center mb-md-0 mb-3">
            <button type="button" class="btn btn-outline-dark mx-2" @click="$router.push('/Member/Login')">
              เข้าสู่ระบบ
            </button>
            <button type="button" class="btn btn-primary">สมัครใช้งาน</button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavbarHeader",
};
</script>

<style>
/* Header ---------------- */
.header-admin {
  z-index: 100;
  position: fixed;
  width: 100%;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.1);
}
.navbar-brand {
  margin-right: 7rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
  font-weight: 500;
  position: relative;
}
</style>
