import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../src/assets/main.scss";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import { createApp } from "vue";
// import App from "./App.vue";

// createApp(App).mount("#app");
import '@mdi/font/css/materialdesignicons.css'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
      },
  })

createApp(App)
.use(vuetify)
.use(router)
.mount("#app");
