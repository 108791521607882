<template>
  <div>
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <NavbarHeader />
    <div class="section-content">
      <router-view />
    </div>
    <footer>
      <FooterBar />
    </footer>
  </div>
</template>

<script>
import NavbarHeader from "./components/NavbarHeader.vue";
import FooterBar from "./components/FooterBar.vue";
export default {
  name: "App",
  components: {
    NavbarHeader,
    FooterBar,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500&family=Sarabun:wght@100;300;400;500&display=swap");
</style>

<style lang="scss">
#app {
  font-family: "Prompt", sans-serif !important;
  font-weight: 400;
  color: #2c3e50;

  .font2 {
    font-family: "Sarabun", sans-serif;
    font-weight: 400;
  }
}
.section-content {
  padding: 70px 0;
}
</style>
